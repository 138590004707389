<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="dataPart"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-4">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="소모품명"
                    name="partName"
                    v-model="dataPart.partName">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text
                    :editable="false"
                    label="실재고"
                    name="realStock"
                    v-model="dataPart.realStock">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-plant :required="true" :editable="editable" type="edit" name="plantCd" v-model="dataPart.plantCd" />
                </div>
                <div class="col-2">
                  <c-dept type="edit" :editable="editable" label="관리부서" name="deptCd" v-model="dataPart.deptCd" />
                </div>
                <div class="col-2">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    label="LBLUSEFLAG"
                    name="useFlag"
                    v-model="dataPart.useFlag"
                  />
                </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 grid-cal-inner" v-show="popupParam.partCd">
        <c-table
          ref="gridIn"
          title="입고이력"
          tableId="gridIn"
          :columns="gridIn.columns"
          :data="gridIn.data"
          :usePaging="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="500px"
          selection="multiple"
          rowKey="partInId"
        >
          <template slot="table-chip">
            <c-datepicker
              :range="true"
              defaultStart="-6M"
              defaultEnd="0M"
              name="period"
              v-model="period"
              :appendToBody="false"
              @datachange="getPartInList"
            />
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" v-if="editable && popupParam.partCd" icon="add" @btnClicked="addRowIn" />
              <c-btn label="LBLREMOVE" v-if="editable && popupParam.partCd" icon="remove" @btnClicked="removeRowIn" />
              <c-btn label="LBLSAVE" v-if="editable && popupParam.partCd" icon="save" @btnClicked="saveRowIn" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 grid-cal-inner" v-show="popupParam.partCd">
        <c-table
          ref="gridOut"
          title="출고이력"
          tableId="gridOut"
          :columns="gridOut.columns"
          :data="gridOut.data"
          :usePaging="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="500px"
          selection="multiple"
          :editable="editable"
          rowKey="partOutId"
          checkDisableColumn="checkFlag"
        >
          <template slot="table-chip">
            <c-datepicker
              :range="true"
              defaultStart="-6M"
              defaultEnd="0M"
              name="period2"
              v-model="period2"
              :appendToBody="false"
              @datachange="getPartInList"
            />
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" v-if="editable && popupParam.partCd" icon="add" @btnClicked="addRowOut" />
              <c-btn label="LBLREMOVE" v-if="editable && popupParam.partCd" icon="remove" @btnClicked="removeRowOut" />
              <c-btn label="LBLSAVE" v-if="editable && popupParam.partCd" icon="save" @btnClicked="saveRowOut" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-part-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          partCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      period: [],
      period2: [],
      dataPart: {
        plantCd: null,  // 사업장코드
        partCd: '',  // 소모품 일련번호
        partName: '',  // 소모품명
        deptCd: '',  // 관리부서
        realStock: '0',  // 실재고수량
        useFlag: 'Y',  // 사용여부
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      listInUrl: '',
      saveInUrl: '',
      deleteInUrl: '',
      listOutUrl: '',
      saveOutUrl: '',
      deleteOutUrl: '',
      gridIn: {
        columns: [
          {
            require: true,
            name: 'inDt',
            field: 'inDt',
            label: '입고일자',
            type: 'date',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            require: true,
            name: 'inStock',
            field: 'inStock',
            type: 'number',
            label: '입고수량',
            style: 'width: 100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '등록자',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
      gridOut: {
        columns: [
          {
            require: true,
            name: 'outDt',
            field: 'outDt',
            label: '출고일자',
            type: 'date',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            require: true,
            name: 'outStock',
            field: 'outStock',
            type: 'number',
            label: '출고수량',
            style: 'width: 100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '등록자',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.mst.part.get.url;
      this.saveUrl = transactionConfig.env.air.mst.part.insert.url;
      this.listInUrl = selectConfig.env.air.mst.part.in.url;
      this.saveInUrl = transactionConfig.env.air.mst.part.in.save.url;
      this.deleteInUrl = transactionConfig.env.air.mst.part.in.delete.url;
      this.listOutUrl = selectConfig.env.air.mst.part.out.url;
      this.saveOutUrl = transactionConfig.env.air.mst.part.out.save.url;
      this.deleteOutUrl = transactionConfig.env.air.mst.part.out.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.partCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {partCd: this.popupParam.partCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.dataPart = _result.data;
          this.updateMode = true;
          this.getPartInList();
          this.getPartOutList();
        },);
      }
    },
    saveInfo() {
      if (this.popupParam.partCd) {
        this.saveUrl = transactionConfig.env.air.mst.part.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.air.mst.part.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.dataPart.regUserId = this.$store.getters.user.userId;
              this.dataPart.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.partCd = result.data.partCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    getPartInList() {
      this.$http.url = this.listInUrl;
      this.$http.param = {partCd: this.popupParam.partCd, srchDts: this.period};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridIn.data = _result.data;
      },);
    },
    addRowIn() {
      this.gridIn.data.push({
        editFlag: 'C',
        partInId: '',  // 소모품 입고 일련번호
        partCd: this.popupParam.partCd,  // 소모품 일련번호
        inDt: this.$comm.getToday(),  // 입고일자
        inStock: null,  // 입고수량
        remark: '',  // 비고
      });
    },
    saveRowIn() {
      if (this.$comm.validTable(this.gridIn.columns, this.gridIn.data)) {
        let saveData = this.gridIn.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveInUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getDetail();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRowIn() {
      let selectData = this.$refs['gridIn'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteInUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    getPartOutList() {
      this.$http.url = this.listOutUrl;
      this.$http.param = {partCd: this.popupParam.partCd, srchDts: this.period2};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridOut.data = _result.data;
      },);
    },
    addRowOut() {
      this.gridOut.data.push({
        editFlag: 'C',
        partOutId: '',  // 소모품 출고 일련번호
        partCd: this.popupParam.partCd,  // 소모품 일련번호
        outDt: this.$comm.getToday(),  // 출고일자
        outStock: null,  // 출고수량
        remark: '',  // 비고
      });
    },
    saveRowOut() {
      if (this.$comm.validTable(this.gridOut.columns, this.gridOut.data)) {
        let saveData = this.gridOut.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveOutUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getDetail();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRowOut() {
      let selectData = this.$refs['gridOut'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteOutUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>